$.ajaxSetup({
	  headers: {"X-Requested-With":"XMLHttpRequest"}
});

jQuery.cachedScript = function(url, options) {

  // allow user to set any option except for dataType, cache, and url
  options = $.extend(options || {}, {
    dataType: "script",
    cache: true,
	async: false,
    url: url
  });

  // Use $.ajax() since it is more flexible than $.getScript
  // Return the jqXHR object so we can chain callbacks
  return jQuery.ajax(options);
};

function get_form_string(el)
{
	var str = '';
	$(el).find('input, select, textarea').each(function(){
		var tn = $(this).get(0).tagName;
		if(tn == 'INPUT' && $(this).attr('type') == 'radio')
		{
			if($(this).prop('checked'))
			{
				str += '&' + $(this).attr('name') + '=' + $(this).val();
			}
		}
		else
		{
			str += '&' + $(this).attr('name') + '=' + $(this).val();
		}

	});
	return str;
}

var xrf = null;
var time = null;

function update_result()
{
	return; /*Если нужен старый поиск убераем эту строчку*/
	if ($('input[name="search_str"]').val()) {
		xrf = $.post('/search', 'a_result=1&search_str=' + $('input[name="search_str"]').val(), function(data){
			$('.search_result').remove();
			if(data!='') $('.search_form').append('<div class="search_result">' + data + '</div>');

			$('.search_box').click(function(){
				location.href = $(this).find('a').attr('href');
			});
		});
		if($('input[name="search_str"]').val() == '') $('.search_result').remove();
	}
}

function compare_show() {
	var otvet = 0;
	var _count;
	$.post(
		'/ishop/compare/count',
		function(html){
			$('.count_compare').html(' (' + html + ')');
			_count = html;
			otvet = 1;
		}
	);

	var t = setInterval(function(){

		if(otvet == 1) {
            //alert(_count);
			clearInterval(t);

			if(_count == 0) {
				$('.compare > a').each(function(){
					$(this).prev('input').prop({'checked' : false});
					$(this).replaceWith('<label for="compare_' + $(this).prev('input').attr('prd-id') + '" title="Добавить товар к сравнению">Добавить к сравнению</label>');


                });
			} else {
                $.post('/ishop/compare',{action:'show'},function(data){
                    $('.compare_product_box').html(data);
                    $('.compare_product').show();
                    //$('.compare_product_box').scroll(function(){$('.td_fixed').css('left',$(this).scrollLeft()+'px');});
                    //$('.compare_product_box').scroll(function(){$('.td_fixed1').css('left',$(this).scrollLeft()+'px');});
					//$('.compare_product_box').scroll(function(){$('.settings_compare').css('left',$(this).scrollLeft()+'px');});
                });
				//('/ishop/compare/show');
			}
		}
	}, 100);
}
function hide_compare(id)
{
    if(id==1) {
        $('.compare > a').each(function () {
            $(this).prev('input').prop({'checked': false});
            $(this).replaceWith('<label for="compare_' + $(this).prev('input').attr('prd-id') + '" title="Добавить товар к сравнению">Добавить к сравнению</label>');


        });
    }
    $('.compare_product').hide();
}

$(function(){
	$('.compare > input').on('change', function(){
		var _obj = $(this).parent('div.compare');
		var id = $(this).attr('prd-id');
		var action = $(this).prop('checked') ? 'add' : 'del';
		$.post(
			'/ishop/compare/' + id,
			{ action : action },
			function(html){
				switch(action){
					case 'add':
						_obj.find('label').remove();
						_obj.append('<a href="javascript:void(0)" class="compare_link" title="Сравнению" onclick="compare_show()">Сравнить<span class="count_compare"> (0)</span></a>');
						break;
					case 'del':
						_obj.find('a').remove();
						_obj.append('<label for="compare_' + id + '" title="Добавить товар к сравнению">Добавить к сравнению</label>');
						break;
				}
				$('.count_compare').html(' (' + html + ')');
			}
		);
	});
});

function update_scripts(){
	$('input[name="search_str"]').keydown( function(){
		if(xrf) xrf.abort();
		if(time) clearTimeout(time);
		time = setTimeout('update_result()', 500);
	});

	$('.src_inp').focus(function(){
		$('.search_result').delay('100').fadeIn();

	});


	$('.src_inp').blur(function(){
		$('.search_result').delay('100').fadeOut();
	});

	$('.ishop_cart').click(function(){
		location.href = location.protocol  + '//' + location.host + '/ishop/cart';
	});

	if($('.star1').length)
	{
		$('.star1').each(function(){
			var prd_id = $(this).attr('prd-id');
			$(this).rating({
				callback: function(value, link){
					$.post('ishop/rating', 'votes=1&product_id=' + prd_id + '&vote=' + value, function(data){
						$('.sr_1').html(data);
					});
				}
			});
		});
	}

	//всплывающее фото
	$.cachedScript('jscripts/highslide/highslide-with-gallery.js');
	$.cachedScript('jscripts/highslide/highslide.config.min.js');

	//для форм
	if($('form').length)
	{
		$('form').append('<input type="hidden" value="js-enabled" name="js-check" />');
	}
}


update_scripts();

$.cachedScript('jscripts/history.min.js')
$.cachedScript('jscripts/ajax_products.js')

$('input[name="reg_send"]').click(function(){
	$.post('/reg/auth', $('form[name="reg_form"]').serialize() + '&reg_ajax=1', function(data){
		if(data == '')
		{
			reg_form.submit();
		}
		else
		{
			eval(data);
		}
		return false;
	});
	return false;
});

function openUrl(url)
{
    var fakeLink = document.createElement('a');

    // Firefox & other browsers
    if (typeof(fakeLink.click) == 'undefined')
    {
        location.href = url;
    }
    // Internet Explorer
    else
    {
		var IE='\v'=='v';
		if(IE) {
			fakeLink.href = url;
			document.body.appendChild(fakeLink);
			fakeLink.click();
		}
		else
		{
			location.href = url;
		}
    }

    return true;
}

function pop_ups() {
	$('.pop_up_fon').fadeIn(200);
	$('.pop_up_block').fadeIn(300);
	setTimeout(function(){
		$('.pop_up_block').fadeOut(200);
		setTimeout(function(){$('.pop_up_block').remove()}, 200);
		$('.pop_up_fon').fadeOut(300);
		setTimeout(function(){$('.pop_up_fon').remove()}, 300);
	}, 7000);
	$('.pop_up_close').click(function(){
		$('.pop_up_block').fadeOut(200);
		setTimeout(function(){$('.pop_up_block').remove()}, 200);
		$('.pop_up_fon').fadeOut(300);
		setTimeout(function(){$('.pop_up_fon').remove()}, 300);
	});
}

function pop_ups_cart() {
	$('.pop_up_block').slideDown(1000);
	setTimeout(function(){
		$('.pop_up_block').slideUp(200);
	}, 7000);
}

function order_call_pop_up() {
	$('.pop_up_fon2').fadeIn(200);
	$('.pop_up_block2').fadeIn(300);
	$('.pop_up_close2').click(function(){
		$('.pop_up_block2').fadeOut(200);
		$('.pop_up_fon2').fadeOut(300);
	});
}

function scroll_to_top(){
	var button = $('.floating_menu_up_wrapper');

	if ($(window).scrollTop() >= 1) {
		$(button).fadeIn('slow');
	}

	$(window).scroll(function() {
		if ($(window).scrollTop() <= 1) {
			$(button).fadeOut('fast');
		}else{
			$(button).fadeIn('slow');
		}
	});
}

scroll_to_top();

// function floating_menu(){
	// var floating_menu = $('.floating_menu');
	// var delta = $(document).height() - $(window).height() - $(window).scrollTop();
	// if(delta > 0) delta = 0;
	// var otstup = 0 - delta;

	// $(floating_menu).css('bottom', otstup + 'px');

	// $(window).scroll(function() {
		// delta = $(document).height() - $(window).height() - $(window).scrollTop();
		// if(delta > 0) delta = 0;
		// otstup = 0 - delta;

		// $(floating_menu).css('bottom', otstup + 'px');

	// });
// }
// floating_menu();

$(function(){
	var cinfo = $('.contact_info_premium').outerWidth();
	var prp = $('.footer_premium_right_part').outerWidth();
	var fpr = $('.footer_premium').width();
	var fpmenu = fpr - prp - cinfo;
	var elcount = $('.footer_premium_menu > ul > div').length;

	$('.footer_premium_menu > ul').width(fpmenu);
	$('.footer_premium_menu > ul > div').width(fpmenu / elcount);
});

// $('.fancybox').fancybox();
function cart_active(){
	$('.cart, .cart span').on('mousedown', function(){
		$('.cart').addClass('cart_active');
	});
	$('.cart, .cart span').on('mouseup mouseout', function(){
		$('.cart').removeClass('cart_active');
	});
}
cart_active();

// document.onselectstart=function(){return false}
// document.ondragstart=function(){return false}
// document.oncontextmenu=function(){return false}
// if($('.content_block input[type=text]').length > 0 || $('.content_block .button').length > 0) {
	// $('.short_description, .product_dop_modes_content').on('mousedown', function(){return false});
// } else {
	// $('.content_block').on('mousedown', function(){return false});
// }
$(window).ready(function(e) {
	$('#paramFilter input, #paramFilter select').change(function(){
		// Массив данных
		var _filters = {
				la_grande_cate: parseInt( $('#paramFilter input[name="la_grande_cate"]').val() ),
				changed:		$.trim( $(this).data('param') )
			},
			_type = $(this).data('type'),
			_this = this,
			_podbor = parseInt( $('#paramFilter').data('podbor') ),
			_ajaxMode = parseInt( $('#paramFilter').data('ajaxmode') ),
			_resetOne = parseInt( $('#paramFilter').data('resetone') );
		// Сбросы по одному
		if( _resetOne ){
			// Проверяем, небыл ли отжат чекбокс
			if( $(this).is(':checkbox') ){
				// Если это был последний чекбокс - скрываем сброс
				if( !$(this).parents('.filter_mid').find('input:checked').length )
					$(this).parents('.filter_mid').prev().find('.blockReset').hide();

			}else if( _type == 'select' && $(this).val() == '' )
				$(this).parents('.filter_mid').prev().find('.blockReset').hide();
			else if( _type == 'range' ){
				$(this).data( 'selected', $(this).val() );
				if(
					$('#paramFilter input[name="'+_filters.changed+'_ot"]').val() == $('#paramFilter input[name="'+_filters.changed+'_ot"]').data('reset') &&
					$('#paramFilter input[name="'+_filters.changed+'_do"]').val() == $('#paramFilter input[name="'+_filters.changed+'_do"]').data('reset')
				)
				$(this).parents('.filter_mid').prev().find('.blockReset').hide();
			}
		}
		// Если фльтрация идет сразу аяксом, при изменении фильтров
		if( _ajaxMode ){
			$('#paramFilter input[type!=checkbox], #paramFilter input[type!=radio], #paramFilter input[type=checkbox]:checked, #paramFilter input[type=radio]:checked, #paramFilter select').map(function(){

				var _param		= $(this).data('param'),
					_thisType	= $(this).data('type'),
					_name		= $(this).attr('name'),
					_val		= _thisType == 'range' ? $.trim( $(this).data('selected') ) : $.trim( $(this).val() );

				if( !_param )
					return;

				if( _filters[_param] === undefined )
					_filters[_param] = { // null нужен для того, чтобы параметр отправлялся в любом случае
						null: null
					};

				if(
					( $(this).is(':checkbox') && $(this).is(':checked') ) ||
					( $(this).is(':radio') && $(this).is(':checked') ) ||
					( !$(this).is(':checkbox') && !$(this).is(':radio') )
				 )
					_filters[_param][_name] = _val ;
			});
		}
		if( _resetOne ){
			// Показываем ресет
			if(
				(  ( $(this).is(':checkbox') || $(this).is(':radio') ) && $(this).parents('.filter_mid').find('input:checked').length ) ||
				( _type == 'select' && $(this).val() != '' ) ||
				(
					_type == 'range' &&
					(
						$('#paramFilter input[name="'+_filters.changed+'_ot"]').val() != $('#paramFilter input[name="'+_filters.changed+'_ot"]').data('reset') ||
						$('#paramFilter input[name="'+_filters.changed+'_do"]').val() != $('#paramFilter input[name="'+_filters.changed+'_do"]').data('reset')
					)
				)
			)
				$(this).parents('.filter_mid').prev().find('.blockReset').show();
		}
		if( _podbor ){
			// Блочим все фильтры на время пост запроса
			$('#paramFilter .filter_mid').map(function(){
				var _params = {
						width: 	$(this).outerWidth(),
						height:	$(this).outerHeight(),
					},
					_loader = $('<div class="citronLoader"><span></span></div>');

				_loader.width( _params.width );
				_loader.height( _params.height );

				$(this).append(_loader);
			});
			// post запрос на новый набор фильтроф

			$.post('/ishop/getFilters', {filters: _filters}, function(data){
				//console.log(data);
				data = $.parseJSON( data );
				//console.log(data);
				for( var i in data ){
					// Не трогаем фильтр, что сейчас был задействован, если в нем есть выбранные элементы
					if(
						( i == _filters.changed ) &&
						(
							_type != 'checkbox' ||
							( _type == 'checkbox' && $(_this).parents('.filter_mid').find('input:checked').length )
						)
					)
						continue;
					// Для каждого типа фильтра свой алгоритм смены допустимых значений  1 - range, 2 - текст, 3 - select, 4 - check
					switch( data[i].type ){
						case '1':
							try{
								var _mn = parseInt(data[i].value.min), _mx = parseInt(data[i].value.max)
								if (isNaN(_mn)) _mn=0;if (isNaN(_mx)) _mx=0;
								$('#paramFilter input[name='+i+'_ot]').val( _mn );
								$('#paramFilter input[name='+i+'_do]').val( _mx );
								$('.slider-range-'+i).slider( "option", {min: _mn} );
								$('.slider-range-'+i).slider( "option", {max: _mx} );
							}catch(e){}
							break;
						case '3':
							try{
								$('#paramFilter select[name="'+i+'"] option[value!=""]').hide();
								for( var j in data[i].value )
								$('#paramFilter select[name="'+i+'"] option[value="'+data[i].value[j]+'"]').show();
							}catch(e){}
							break;
						case '4':
							try{
								$('#paramFilter input[data-param="'+i+'"]').parent().hide();
								for( var j in data[i].value )
									$('#paramFilter input[data-param="'+i+'"][value="'+data[i].value[j]+'"]').parent().show();
							}catch(e){}
							break;
						default: ;
					}
				}
				$('#paramFilter .filter_mid .citronLoader').remove();
			});
		}
		if( _ajaxMode )
			sendFilterForm();

	});
	// Кнопка сбороса 1 фильтра
	$('#paramFilter .filter_top .blockReset').click(function(){

		var _toReset = $(this).parent().next().find('input, select');
			_length = _toReset.length - 1;

		_toReset.map(function(e){

			var _param	= $(this).data('param'),
			_type 	= $(this).data('type');

			if( _type == 'range' ){
				var _reset = parseInt($(this).data('reset'));

				if( $(this).attr('name') == _param+'_ot' )
					$('.slider-range-'+_param).slider( "option", {min: _reset} );
				else
					$('.slider-range-'+_param).slider( "option", {max: _reset} );

				$(this).val( _reset ).data('selected', _reset).trigger('keyup');


			}else if( _type == 'checkbox' )
				$(this).prop('checked', false);
			else if( _type == 'select' )
				$(this).prop('selected', false);

			if( e == _length )
				$(this).trigger('change');
		});

		$(this).hide();
		return false;
	});
	// Скрытие блока 1 фильтра
	$('#paramFilter .filter_top').click(function(e){

		// if( $(this).hasClass('closed') )
		// 	$(this).removeClass('closed').next().show();
		// else
		// 	$(this).addClass('closed').next().hide();

		$(this).next().slideToggle(200);
		$(this).find('.filter__icon').toggleClass('filter__icon_active');


	});

	$('#js-filter-title').on('click', function() {
		$(this).find('.filter__title-arrow').toggleClass('filter__title-arrow_active');
		$(this).next().slideToggle(300);
	});
});
// Отправка формы ajax
function sendFilterForm(){
	var _post = $('#paramFilter form').serialize(),
		_page = $('#paramFilter form').data('page'),
		_loaderBig = $('<div class="citronLoaderBig"><span></span></div>');
		_loaderBig.width( $('#main__content').outerWidth() );
		_loaderBig.height( $('#main__content').outerHeight() );

	$('#main__content').append(_loaderBig);

	$.post('/ishop/advsearch/' + _page, _post, function(data){
		$('#main__content').html(data).addClass('filtrovannoe');
		$('#main__content .citronLoaderBig').remove();
		$('#main__content a').prop('target', '_blank');
		$('#main__content .pagination a').click(function(event){
			event.preventDefault();
			$('#paramFilter form').data('page', parseInt( $(this).html() ));
			sendFilterForm();
		});
		$('.content_block .sort_panel a').click(function(event){
			event.preventDefault();
			$('#paramFilter form input[name=sname]').val( $(this).data('sname') );
			$('#paramFilter form input[name=ssort]').val( $(this).data('ssort') );
			sendFilterForm();
		});
	});
}
// Сброс всех фильров
function resetAllFilters( _form ){
	_form = _form || $('#paramFilter form');
	var _toReset	= _form.find('input, select'),
		_length		= _toReset.length - 1;

	if( parseInt(_form.data('ajaxmode')) && !_form.find('.blockReset:visible').length )
		return;

	_toReset.map(function(e){

		var _param	= $(this).data('param'),
			_type 	= $(this).data('type');

		if( _type == 'range' ){
			var _reset = parseInt($(this).data('reset'));

			if( $(this).attr('name') == _param+'_ot' )
				$('.slider-range-'+_param).slider( "option", {min: _reset} );
			else
				$('.slider-range-'+_param).slider( "option", {max: _reset} );

			$(this).val( _reset ).data('selected', _reset).trigger('keyup');


		}else if( _type == 'checkbox' )
			$(this).prop('checked', false);
		else if( _type == 'select' )
			$(this).prop('selected', false);

		if( e == _length )
			$(this).trigger('change');
	});
	_form.find('.blockReset').hide();
}

function addToFavoriteProducts( _id, _this ){
	$.post('/ishop/favorite',{ajaxeg: 'add_favorite', id: _id}, function(data){
		if( data == '1' ){
			$(_this).replaceWith('<a href="javascript:void(0)" onClick="delFromFavoriteProducts('+_id+', this);" class="tofavorite">Удалить из избранного</a>');
		}else
			console.log(data);
	});
}

function delFromFavoriteProducts( _id, _this ){
	$.post('/ishop/favorite',{ajaxeg: 'del_favorite', id: _id}, function(data){
		if( data == '1' ){
			$(_this).replaceWith('<a href="javascript:void(0)" onClick="addToFavoriteProducts('+_id+', this);" class="tofavorite">Добавить в  избранное</a>');
			if( location.pathname == '/ishop/favorite' ){
				$('.hproduct[data-id='+_id+']').remove();
				if( !$('#content_inner .hproduct').length )
					$('.zameniMenyaNaFiltrovannoe').html('Вы еще ничего не добавили в избранное');
			}
		}else
			console.log(data);
	});
}


function showFastCard( _id ){
	$.post('/ishop/product/'+_id, {ajaxeg: 'get_fast_card', id: _id}, function(data){
	addFastProductWindow(data);
	update_scripts();
	$.cachedScript('jscripts/ajax_products.js');
	});
}

function addFastProductWindow( _html ) {
	var scrollPos = $(window).scrollTop();

	$('body').append('<div id="parent_fast_window"><div id="layerok"></div><div id="fast_window">' + _html + '</div><div>');
	$('html,body').css('overflow','hidden');
	$('#fast_window').append('<div id="close_fast_window" title="Закрыть окно">X</div>');

	$('#layerok, #close_fast_window').click(function(){
		$('html,body').css('overflow','auto');
		$('#parent_fast_window, #layerok').remove();
	});
}

/*Развертка вопрос ответа*/
$(function() {
	$('.js-faq__msg').on('click', function() {
		var _elem = $(this);
		_elem.parent().next().slideToggle();
		_elem.toggleClass('js-faq__active');
	});
});



// fixed_menu

$(function() {

			resizeScroll();

        $(window).resize(function () {
        	resizeScroll();
        });

    function resizeScroll() {
        var windWidth = $(window).width();
        //console.log(windWidth);
        // debugger;
        if(windWidth >= 992) {
            var $header = $(".header");
            var header_full = $header.outerHeight();
            $(window).on( 'scroll', function(){
                var $otstup_top = $(window).scrollTop(),
                    $header = $(".header"),
                    $mainBlock = $('.main'),
                    header_h = $header.outerHeight();
                if ($otstup_top >= header_full) {
                    // console.log($otstup_top >= header_full);
                    //debugger;
                    $header.addClass("header-fixed");
                    $mainBlock.css({
                        'margin-top': header_h + header_full + 'px'
                    });
                }
                else {
                    // console.log($otstup_top >= header_full);
                    $header.removeClass("header-fixed");
                    $mainBlock.css({
                        'margin-top': '0px'
                    });

                };
                //console.log($otstup_top, header_h)
            });

        }else{
            $(window).off( 'scroll');
            $('.main').css({
                'margin-top': '0px'
            });
        }
    }

});



$(document).ready(function () {
	var topSwiper = new Swiper ('.swiper-container-top', {
		direction: 'horizontal',
		loop: true,
		nextButton: '.slider__button-next',
		prevButton: '.slider__button-prev'
	});

    var bottomSwiper = new Swiper ('.swiper-container-bottom', {
        direction: 'horizontal',
        loop: true,
        nextButton: '.slider__button-next2',
        prevButton: '.slider__button-prev2'
    });
});


var changeTarif = function(id) {
	// Функция обработки тарифной сетки .... мать её
	var tarifFor = $('input[name=switch]:checked').val();

	$.post('/ishop/tarif', {switch: tarifFor, id: id}, function(data){
		$('.tab__content-wrap').html(data);
		//console.log(data);
	});

	
}

onReady(function(){
	$('.locality-btn').on('click', function(elem){
		elem.preventDefault();

		var id = $('.tariff__right option').val();
		console.log(!!!!!!!!!!!!!!typeof id); 
		changeTarif(id);
	});

    $('input[name=switch]').on('change', function(){
    	var id = $('.tariff__right option').val();
        changeTarif(id);
    });


    $('body').on('click', '.tariff__btn_not-active', function(){

    	var id = $(this).attr('data-id');

    	$('input[name=tarifId]').val(id);

    	$('.tariff__item').removeClass('tariff__item_active');
    	$(this).parents('.tariff__item').addClass('tariff__item_active'); 

    	$('.tariff__btn').removeClass('tariff__btn_active').addClass('tariff__btn_not-active');
    	$('.tariff__btn').find('.tariff__btn-text').text('Выбрать');
    	
    	$(this).removeClass('tariff__btn_not-active').addClass('tariff__btn_active');
    	$(this).find('.tariff__btn-text').text('Подключить');

    	// Подкидываем в итоговые значения
    	var data = {
    		title : $(this).parents('.tariff__item').find('.tariff__name-text').text(),
    		price : $(this).parents('.tariff__item').find('.tariff__price-text').text(),
    	};

    	$('.tariff__choice-info-name').text(data.title);
    	$('.tariff__choice-sum').text(data.price + ' рублей');

    });

    $('body').on('click', '.tariff__btn_active', function(){
    	$('.c-callorder__popup').show();
    });

});

$(document).ready(function () {
    $('#select-locality').selectize();
    $('#select-locality-top').selectize();
});


